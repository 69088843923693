<template>
  <div class="gmv-process-modal">
    <b-modal
      class="gmv-process-modal-container"
      id="admin-modal"
      scrollable
      title="Procese sus documentos"
    >
      <template>
        <p class="gmv-process-modal-form__title">
          Seleccionar el banco el cual corresponde el proceso
        </p>
        <el-radio-group
          v-if="$route.path.toLowerCase().includes('administrador')"
          class="radio-banks"
          v-model="parametersBanks"
        >
          <el-radio v-for="bank in banksArray" :label="bank" :key="bank.id">{{
            bank.name
          }}</el-radio>
        </el-radio-group>
      </template>
      <div>
        <b-card no-body>
          <b-tabs
            card
            v-if="
              $route.path.toLowerCase().includes('administrador') &&
              parametersBanks
            "
          >
            <b-tab
              active
              title="Base final"
              v-if="parametersBanks.name.toLowerCase() === 'bancolombia'"
            >
              <form id="form" name="form" v-on:submit.prevent="">
                <b-card-text
                  v-loading="Loading"
                  element-loading-text="Procesando..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(245 , 229, 229, 0.8)"
                  style="width: 100%"
                >
                  <div class="inputs">
                    <label class="inputs__title">Archivos</label>
                    <div class="inputs__grid">
                      <b-input
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="ruta_base"
                        size="sm"
                        type="text"
                        class="gmv-process-modal-form__inputs"
                        v-model="processFinalBase.ruta_base"
                        placeholder="Ruta base*"
                      ></b-input>
                      <b-form-select
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        :options="filesDesmontes"
                        class="gmv-process-modal-form__inputs"
                        v-model="processFinalBase.ruta_desmontes"
                      >
                        <template #first
                          ><b-form-select-option disabled
                            >--Por favor seleccione una ruta desmontes
                            --</b-form-select-option
                          ></template
                        >
                      </b-form-select>
                    </div>
                  </div>
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">No interesados</label>
                    <div class="inputs__grid">
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_inicial_no_interesados"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="processFinalBase.fecha_inicial_no_interesados"
                        placeholder="Fecha inicial*"
                      ></b-form-datepicker>
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_final_no_interesados"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="processFinalBase.fecha_final_no_interesados"
                        placeholder="Fecha final*"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">Rediferidos</label>
                    <div class="inputs__grid">
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_inicial_no_rediferidos"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="processFinalBase.fecha_inicial_rediferidos"
                        placeholder="Fecha inicial*"
                      ></b-form-datepicker>
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_final_no_rediferidos"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="processFinalBase.fecha_final_rediferidos"
                        placeholder="Fecha final*"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <hr />
                  <div class="inputs">
                    <label class="inputs__title">Rediferidos 360</label>
                    <div class="inputs__grid">
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_inicial_rediferidos_360"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="processFinalBase.fecha_inicial_rediferidos_360"
                        placeholder="Fecha inicial*"
                      ></b-form-datepicker>
                      <b-form-datepicker
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="fecha_final_rediferidos_360"
                        size="sm"
                        locale="es"
                        class="gmv-process-modal-form__date"
                        v-model="processFinalBase.fecha_final_rediferidos_360"
                        placeholder="Fecha final*"
                      ></b-form-datepicker>
                    </div>
                  </div>
                  <hr />
                  <div class="gmv-process-modal-button">
                    <Button
                      v-if="
                        parametersBanks.name.toLowerCase() === 'bancolombia'
                      "
                      class="upload-button"
                      type="submit"
                      @click="postFinalBase"
                      >Procesar
                    </Button>
                  </div>
                </b-card-text>
              </form>
            </b-tab>
            <b-tab
              active
              title="Base teléfonos"
              v-if="parametersBanks.name.toLowerCase() === 'santander'"
            >
              <form id="form" name="form" v-on:submit.prevent="">
                <b-card-text
                  v-loading="Loading"
                  element-loading-text="Procesando..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(245 , 229, 229, 0.8)"
                  style="width: 100%"
                >
                  <div class="inputs">
                    <label class="inputs__title">Archivos</label>
                    <div class="inputs__grid">
                      <b-input
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="ruta telefonos moviles"
                        size="sm"
                        type="text"
                        class="gmv-process-modal-form__inputs"
                        v-model="processTelephoneBase.cellphones_route"
                        placeholder="Ruta teléfonos móviles*"
                      ></b-input>
                    </div>
                  </div>
                  <hr />
                  <div class="gmv-process-modal-button">
                    <Button
                      v-if="parametersBanks.name.toLowerCase() === 'santander'"
                      class="upload-button"
                      type="submit"
                      @click="postTelephoneBase"
                      >Procesar
                    </Button>
                  </div></b-card-text
                >
              </form>
            </b-tab>
            <b-tab
              active
              title="Base comercial"
              v-if="parametersBanks.name.toLowerCase() === 'bbva'"
            >
              <form id="form" name="form" v-on:submit.prevent="">
                <b-card-text
                  v-loading="Loading"
                  element-loading-text="Procesando..."
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(245 , 229, 229, 0.8)"
                  style="width: 100%"
                >
                  <div class="inputs">
                    <label class="inputs__title">Archivos</label>
                    <div class="inputs__grid">
                      <b-input
                        required
                        title="Este campo es obligatorio"
                        aria-label="required"
                        name="ruta preaprobados"
                        size="sm"
                        type="text"
                        class="gmv-process-modal-form__inputs"
                        v-model="processComercialBase.comercial_path"
                        placeholder="Ruta preaprobados*"
                      ></b-input>
                    </div>
                  </div>
                  <hr />
                  <div class="gmv-process-modal-button">
                    <Button
                      v-if="parametersBanks.name.toLowerCase() === 'bbva'"
                      class="upload-button"
                      type="submit"
                      @click="postComercialBase"
                      >Procesar
                    </Button>
                  </div></b-card-text
                >
              </form>
            </b-tab>
            <template #tabs-end>
              <li role="presentation" style="margin-left: 35rem">
                Tipo de procesos
              </li>
            </template>
          </b-tabs>
        </b-card>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import Button from "primevue/button";
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
export default {
  inject: ["reload"],
  name: "AdminProcessModal",
  props: {
    fileInfo: Object,
  },
  data() {
    return {
      filesDesmontes: [],
      banks: {
        name: null,
      },
      Loading: false,
      parametersBanks: null,
      processFinalBase: {
        ruta_base: null,
        ruta_desmontes: null,
        fecha_inicial_no_interesados: null,
        fecha_final_no_interesados: null,
        fecha_inicial_referidos: null,
        fecha_final_referidos: null,
        fecha_inicial_referidos_360: null,
        fecha_final_referidos_360: null,
      },
      processTelephoneBase: {
        cellphones_route: null,
      },
      processComercialBase: {
        comercial_path: null,
      },
    };
  },
  components: {
    Button,
    Loading,
  },
  created() {
    this.filesSelectDesmontes();
  },
  computed: {
    ...mapGetters({ banksArray: "banks/showBanks" }),
  },
  watch: {
    allBanks() {
      this.parametersBanks = this.banksArray[0];
    },
    fileInfo() {
      this.cleanInputs();
      if (
        this.fileInfo.type == "contener" ||
        this.fileInfo.type == "revolvente"
      ) {
        this.processFinalBase.ruta_base = this.fileInfo.name;
      } else if (this.fileInfo.type == "rechazos_simulacion") {
        this.processBexBase.ruta_rechazos = this.fileInfo.name;
      } else if (this.fileInfo.type == "telefonos") {
        this.processTelephoneBase.cellphones_route = this.fileInfo.name;
      } else if (this.fileInfo.type == "pre_aprobados") {
        this.processComercialBase.comercial_path = this.fileInfo.name;
      }
    },
  },
  methods: {
    async filesSelectDesmontes() {
      let paramsModal = {
        type: 'desmontes',
        skip: 0,
        limit: 1000,
      };
      let response = await this.$store.dispatch("files/getFiles", paramsModal);
      this.filesDesmontes = response.data.map((el) => el.name);
    },
    async postFinalBase() {
      this.Loading = true;
      let result = await this.$store.dispatch(
        "processBase/postFinalBase",
        this.processFinalBase
      );
      this.Loading = false;
      if (result.status == 200) {
        this.reload({ name: "Administrador" });
      }
    },
    validations() {
      this.processFinalBase = {
        base: { required },
        ruta_base: { required },
        ruta_desmontes: { required },
      };
      this.processTelephoneBase = {
        cellphones_route: { required },
      };
      this.processComercialBase = {
        comercial_path: { required },
      };
    },
    async postTelephoneBase() {
      this.Loading = true;
      let result = await this.$store.dispatch(
        "processBase/postTelephoneBase",
        this.processTelephoneBase
      );
      this.Loading = false;
      if (result.status == 200) {
        this.reload({ name: "Administrador" });
      }
    },
    async postComercialBase() {
      this.Loading = true;
      let result = await this.$store.dispatch(
        "processBase/postComercialBase",
        this.processComercialBase
      );
      this.Loading = false;
      if (result.status == 200) {
        this.reload({ name: "Administrador" });
      }
    },
    cleanInputs() {
      (this.processFinalBase = {
        ruta_base: null,
        ruta_desmontes: null,
        fecha_inicial_no_interesados: null,
        fecha_final_no_interesados: null,
        fecha_inicial_referidos: null,
        fecha_final_referidos: null,
        fecha_inicial_referidos_360: null,
        fecha_final_referidos_360: null,
      }),
        (this.processTelephoneBase = {
          cellphones_route: null,
        });
      this.processComercialBase = {
        comercial_path: null,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@include upload-button;
::v-deep .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60rem;
  pointer-events: auto;
  background-color: #fff;
  border-radius: 0.3rem;
}
::v-deep .modal.show .modal-dialog {
  transform: none;
  max-width: 60rem;
}
.el-radio-group {
  display: flex;
  justify-content: center;
}
::v-deep button.close {
  border: none;
  background: none;
}
hr {
  margin-bottom: 0rem;
}
.inputs {
  display: flex;
  flex-direction: column;
  &__title {
    color: #717274;
    margin-bottom: 0;
  }
  &__grid {
    display: flex;
  }
}
.gmv-process-modal-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  &__inputs {
    color: black;
    background-color: white;
    margin: 5px;
    height: auto;
  }
  &__select {
    background-color: white;
    margin-top: 5px;
    height: 35px;
    border-radius: 5px;
  }
  &__title {
    color: $color-primary-company;
    text-align: center;
    padding-top: 15px;
  }
  &__date {
    text-align: end;
    margin: 5px;
    border: 1px solid #ced4da;
  }
}
.gmv-process-modal-button {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
::v-deep .modal-footer {
  display: none;
}
</style>